import * as API from "@/services/API";

export default {
    registrarBoleta(payload) {
        return API.apiClient.post('/ordenes-trabajo/lista-ot/boleta', payload);
    },
    getBoletasOTDiarias(fecha) {
        return API.apiClient.get(`/reportes/caja-diaria/boletas-ot/${fecha}`);
    },
}
