<template>
  <div class="bg-blue-50 px-6">
      <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
          <div class="px-12 py-6">
              <h1 
              class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left whitespace-no-wrap">
                <faIcon
                icon="cash-register"
                type="fa"
                class="text-green-500 text-3xl"
                >
                </faIcon>
                Caja diaria
              </h1>
              <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Aquí tienes un resumen de lo que ha ocurido durante el día.</p>
          </div>
          <div class="pr-10 pb-6 pl-10 lg:pb-0 self-center flex flex-col sm:flex-row">
            <DatePicker
            :attributes="atributosDatePicker"
            v-model="fechaSeleccionada"
            mode="date"
            :model-config="modelConfig"
            @dayclick="consultarOTsDiarias(fechaSeleccionada), consultarAbonosDiarios(fechaSeleccionada), consultarVentasBazarDiarias(fechaSeleccionada), consultarBoletasOTDiarias(fechaSeleccionada)"
            class="mt-3 sm:mt-0"
            >
            <template v-slot="{ inputValue, inputEvents }">
                <input
                class="form-input box border-gray-500 text-center"
                :value="inputValue"
                v-on="inputEvents"
                >
            </template>
            </DatePicker>
            <select 
            v-model="sucursalSeleccionada"
            :disabled="!isAdmin"
            class="sm:ml-2 mt-3 sm:mt-0 sm:w-auto form-select box border-gray-500">
                <option 
                v-for="sucursal in this.sucursalesDisponiblesFiltradas" 
                :key="sucursal.suc_id" 
                :value="sucursal.suc_id"
                > 
                    <option>
                    {{ sucursal.suc_nombre }}                  
                    </option>
                </option>
            </select>              
          </div>
      </div>

      <div class="grid grid-cols-12 gap-4 mt-4" :key="keyRecarga">
          
          <div class="col-span-12 bg-white rounded-lg shadow-md p-5">
              <div class="flex flex-col">
                <div class="px-1 py-4 text-2xl text-gray-800">
                    <div class="border-b border-gray-300 pb-5">Ventas del día</div>   
                </div>               
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 z-0">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-blue-500">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                            Nº 
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                            tipo venta
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                            hora
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                            total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200 text-gray-900">
                                    <tr v-for="(ot,indice) in listaOTs[sucursalSeleccionada]" :key="indice">
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ ot.numeroOt }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                OT 
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ ot.horaCreacion }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ `$ ${formatearCifra(ot.valorTotal)}` }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-for="(venta,indice) in listaBazar[sucursalSeleccionada]" :key="indice">
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ venta.vnt_num }} 
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                Bazar
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ venta.hora }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ `$ ${formatearCifra(venta.vnt_total)}` }}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="shadow rounded-lg mt-3 md:text-3xl text-2xl py-6 px-3 sm:pr-32 border-t border-gray-100">
                    <div class="flex md:flex-row-reverse flex-col">
                        <div class="text-blue-700 px-1 whitespace-no-wrap">
                            {{ `$ ${formatearCifra(totalDiario)}` }}
                        </div> 
                        <div class="text-gray-800 px-1">
                            Total de ventas:
                        </div>
                    </div>
                </div>
            </div>
          </div>

          <div class="col-span-12 bg-white rounded-lg shadow-md p-5">
              <div class="flex flex-col">
                <div class="px-1 py-4 text-2xl text-gray-800">
                    <div class="border-b border-gray-300 pb-5">Operaciones de caja</div>        
                </div>               
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="text-left py-3 pl-1 text-lg text-gray-900">Abonos</div>
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-blue-500">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                            Nº OT
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                            Nº boleta
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                            hora
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                            método pago
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                            monto
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200 text-gray-900">
                                    <tr v-for="(abono,indice) in listaAbonos[sucursalSeleccionada]" :key="indice">
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ abono.numeroOT }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ abono.boleta ? abono.boleta.datosBoleta.numeroBoleta : '' }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ abono.hora }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ abono.medioPago }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ `$ ${formatearCifra(abono.montoAbono)}` }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-for="(boleta,indice) in listaBoletasOT[sucursalSeleccionada]" :key="indice"
                                    class=""
                                    >
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ boleta.numeroOT }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                {{ boleta.datosBoleta.numeroBoleta }}
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="text-center">
                                                
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap">
                                            <div class="flex justify-center text-center">
                                                <div class="px-3 rounded-full bg-green-500 text-white">
                                                    Finalización
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="pt-5">
                            <div class="text-left py-3 pl-1 text-lg text-gray-900">Bazar</div>
                            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-200 text-gray-900">
                                    <thead class="bg-blue-500">
                                        <tr>
                                            <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                                Nº venta
                                            </th>
                                            <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                                Nº boleta
                                            </th>
                                            <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                                hora
                                            </th>
                                            <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                                método pago
                                            </th>
                                            <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                                total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <tr v-for="(venta,indice) in listaBazar[sucursalSeleccionada]" :key="indice">
                                            <td class="px-6 py-4 whitespace-no-wrap">
                                                <div class="text-center">
                                                    {{ venta.vnt_num }}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4 whitespace-no-wrap">
                                                <div class="text-center">
                                                    {{ venta.boleta ? venta.boleta.datosBoleta.numeroBoleta : '' }} 
                                                </div>
                                            </td>
                                            <td class="px-6 py-4 whitespace-no-wrap">
                                                <div class="text-center">
                                                    {{ venta.hora }}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4 whitespace-no-wrap">
                                                <div class="text-center">
                                                    {{ venta.metodoPago.metodo }}
                                                </div>
                                            </td>
                                            <td class="px-6 py-4 whitespace-no-wrap">
                                                <div class="text-center">
                                                    {{ `$ ${formatearCifra(venta.vnt_total)}` }}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-span-12 bg-white rounded-lg shadow p-5 flex flex-col mt-3 border-t border-gray-100">
                    <div class="px-1 py-4 text-2xl text-gray-800">
                        <div class="border-b border-gray-300 pb-5">
                            Total de caja   
                        </div>
                    </div>
                    <div class="grid grid-cols-4 py-6">
                        <div class="flex justify-center xl:col-span-1 col-span-4 2xl:text-3xl text-xl xl:border-r border-gray-400">
                            <div class="text-center py-2 pl-4 pt-2">Efectivo:</div>
                            <div class="text-left py-2 pl-4 text-blue-700 whitespace-no-wrap">{{ `$ ${this.formatearCifra(totalCajaEfectivo)}` }}</div>
                        </div>
                        <div class="flex justify-center xl:col-span-1 col-span-4 2xl:text-3xl text-xl xl:border-r border-gray-400">
                            <div class="text-left py-2 pl-4 pt-2">Transbank:</div>
                            <div class="text-left py-2 pl-4 text-blue-700 whitespace-no-wrap">{{ `$ ${this.formatearCifra(totalCajaTransbank)}` }}</div>
                        </div>
                        <div class="flex justify-center xl:col-span-1 col-span-4 2xl:text-3xl text-xl xl:border-r border-gray-400">
                            <div class="text-left py-2 pl-4 pt-2">Cheque:</div>
                            <div class="text-left py-2 pl-4 text-blue-700 whitespace-no-wrap">{{ `$ ${this.formatearCifra(totalCajaCheque)}` }}</div>
                        </div>
                        <div class="flex justify-center xl:col-span-1 col-span-4 2xl:text-3xl text-xl">
                            <div class="text-left py-2 pl-4 pt-2">Convenio:</div>
                            <div class="text-left py-2 pl-4 text-blue-700 whitespace-no-wrap">{{ `$ ${this.formatearCifra(totalCajaConvenio)}` }}</div>
                        </div>
                    </div>
                </div>

            </div>
          </div>

      </div>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar"

import VentaService from '@/services/VentasService';
import AbonoService from '@/services/AbonoService';
import OTService from '@/services/OrdenTrabajoService';
import BoletaService from '@/services/BoletaService';

import { separarPorMiles } from '@/utils/helpers';
import { mapGetters } from "vuex";
import moment from "moment"

export default {
    name: 'CajaDaria',
    data() {
        return {
            sucursalUsuario: null,
            ventasBazarDiarias: null,
            abonosDiarios: null,
            otDiarias: null,
            boletasOTDiarias: null,
            sucursalSeleccionada: 1,
            listaBazar: [],
            listaOTs: [],
            listaAbonos: [],
            listaBoletasOT: [],
            atributosDatePicker: [
                {
                    key: 'today',
                    highlight: true,
                    dates: null,
                }
            ],
            modelConfig: {
                type: 'string',
                mask: 'DD-MM-YYYY'
            },
            fechaSeleccionada: moment(new Date()).format('DD-MM-YYYY'),
            sucursalesDisponibles: [],
            sucursalesFiltradas: []
        }
    },
    methods: {
        consultarOTsDiarias(fecha) {
            OTService.getReporteOtDiario(fecha)
            .then((response) => {
                this.otDiarias = response.data;
                this.setListaOTs();
            });
        },
        consultarAbonosDiarios(fecha) {
            AbonoService.getAbonosDiarios(fecha)
            .then((response) => {
                this.abonosDiarios = response.data;
                this.setListaAbonos();
            });
        },
        consultarVentasBazarDiarias(fecha) {
            VentaService.getVentasDiarias(fecha)
            .then((response) => {
                this.ventasBazarDiarias = response.data;
                this.setListaVentas();
            });
        },
        consultarBoletasOTDiarias(fecha) {
            BoletaService.getBoletasOTDiarias(fecha)
            .then((response) => {
                this.boletasOTDiarias = response.data;
                this.setListaBoletasOT();
            });
        },
        setListaOTs() {
            let lista = [];
            if (this.otDiarias['1']) {
                lista[1] = this.otDiarias['1'];
            }
            if (this.otDiarias['2']) {
                lista[2] = this.otDiarias['2'];
            }
            if (this.otDiarias['3']) {
                lista[3] = this.otDiarias['3'];
            }

            this.listaOTs = lista;
        },
        setListaAbonos() {
            let lista = [];
            if (this.abonosDiarios['1']) {
                lista[1] = this.abonosDiarios['1'];
            }
            if (this.abonosDiarios['2']) {
                lista[2] = this.abonosDiarios['2'];
            }
            if (this.abonosDiarios['3']) {
                lista[3] = this.abonosDiarios['3'];
            }

            this.listaAbonos = lista;
        },
        setListaVentas() {
            let lista = [];
            if (this.ventasBazarDiarias['1']) {
                lista[1] = this.ventasBazarDiarias['1'];
            }
            if (this.ventasBazarDiarias['2']) {
                lista[2] = this.ventasBazarDiarias['2'];
            }
            if (this.ventasBazarDiarias['3']) {
                lista[3] = this.ventasBazarDiarias['3'];
            }

            this.listaBazar = lista;
        },
        setListaBoletasOT() {
            let lista = [];
            if (this.boletasOTDiarias['1']) {
                lista[1] = this.boletasOTDiarias['1'];
            }
            if (this.boletasOTDiarias['2']) {
                lista[2] = this.boletasOTDiarias['2'];
            }
            if (this.boletasOTDiarias['3']) {
                lista[3] = this.boletasOTDiarias['3'];
            }

            this.listaBoletasOT = lista;
        },
        calcularTotalesCaja() {
            this.totalesCaja.efectivo = this.totalesVentasBazar.efectivo + this.totalesAbono.efectivo;
            this.totalesCaja.transbank = this.totalesVentasBazar.transbank + this.totalesAbono.transbank;
            this.totalesCaja.cheque = this.totalesVentasBazar.cheque + this.totalesAbono.cheque;
            this.totalesCaja.convenio = this.totalesVentasBazar.convenio + this.totalesAbono.convenio;
        },
        formatearCifra(cifra) {
            return separarPorMiles(cifra);
        }
    },
    mounted() {
        let user = this.$store.getters["auth/authUser"];
        this.sucursalUsuario = user.sucursal;
        this.sucursalSeleccionada = this.idSucursalUsuario;

        if (!this.$store.getters.sucursales) {
            this.$store.dispatch("sucursal/getSucursales").then(() => {
                this.sucursalesDisponibles = this.sucursales;

                for (let index = 0; index < this.sucursalesDisponibles.length; index++) {
                    const sucursal = this.sucursalesDisponibles[index];

                    if (sucursal.suc_id == this.idSucursalUsuario || this.isAdmin) {
                        this.sucursalesFiltradas.push(sucursal);
                    } 
                }
            });
        }

        this.consultarOTsDiarias(this.fechaSeleccionada);
        this.consultarAbonosDiarios(this.fechaSeleccionada);
        this.consultarVentasBazarDiarias(this.fechaSeleccionada);
        this.consultarBoletasOTDiarias(this.fechaSeleccionada);
    },
    computed: {
        ...mapGetters("auth", ["isAdmin"]),
        ...mapGetters("sucursal", ["sucursales"]),
        sucursalesDisponiblesFiltradas: function() {
            return this.sucursalesFiltradas;
        },
        idSucursalUsuario: function() {
            return this.sucursalUsuario.id;
        },
        totalOTs: function() {
            let total = 0;
            let listaOTs = this.listaOTs[this.sucursalSeleccionada];

            if (listaOTs != null) {
                let sumaTotales = 0;
                for (let index = 0; index < listaOTs.length; index++) {
                    const element = listaOTs[index];
                    sumaTotales += element.valorTotal;
                }

                total = sumaTotales;
            }

            return total;
        },
        totalBazarEfectivo: function() {
            let total = 0;
            let listaBazar = this.listaBazar[this.sucursalSeleccionada];

            if (listaBazar != null) {
                let sumaEfectivo = 0;
                for (let index = 0; index < listaBazar.length; index++) {
                    const element = listaBazar[index];
                    if (element.metodoPago.metodo == 'Efectivo') {
                        sumaEfectivo+= element.vnt_total;
                    }
                }

                total = sumaEfectivo;
            }
        
            return total;
        },
        totalBazarTransbank: function() {
            let total = 0;
            let listaBazar = this.listaBazar[this.sucursalSeleccionada];

            if (listaBazar != null) {
                let sumaTransbank = 0;
                for (let index = 0; index < listaBazar.length; index++) {
                    const element = listaBazar[index];
                    if (element.metodoPago.metodo == 'Transbank') {
                        sumaTransbank+= element.vnt_total;
                    }
                }

                total = sumaTransbank;
            }
        
            return total;
        },
        totalBazarCheque: function() {
            let total = 0;
            let listaBazar = this.listaBazar[this.sucursalSeleccionada];

            if (listaBazar != null) {
                let sumaCheque = 0;
                for (let index = 0; index < listaBazar.length; index++) {
                    const element = listaBazar[index];
                    if (element.metodoPago.metodo == 'Cheque') {
                        sumaCheque+= element.vnt_total;
                    }
                }

                total = sumaCheque;
            }
        
            return total;
        },
        totalBazarConvenio: function() {
            let total = 0;
            let listaBazar = this.listaBazar[this.sucursalSeleccionada];

            if (listaBazar != null) {
                let sumaConvenio = 0;
                for (let index = 0; index < listaBazar.length; index++) {
                    const element = listaBazar[index];
                    if (element.metodoPago.metodo == 'Convenio') {
                        sumaConvenio+= element.vnt_total;
                    }
                }

                total = sumaConvenio;
            }
        
            return total;
        },
        totalBazarGeneral: function() {
            return this.totalBazarEfectivo + this.totalBazarTransbank + this.totalBazarCheque + this.totalBazarConvenio;
        },
        totalAbonosEfectivo: function() {
            let total = 0;
            let listaAbonos = this.listaAbonos[this.sucursalSeleccionada];

            if (listaAbonos != null) {
                let sumaEfectivo = 0;
                for (let index = 0; index < listaAbonos.length; index++) {
                    const element = listaAbonos[index];
                    if (element.medioPago == 'Efectivo') {
                        sumaEfectivo+= element.montoAbono;
                    }
                }

                total = sumaEfectivo;
            }
        
            return total;
        },
        totalAbonosTransbank: function() {
            let total = 0;
            let listaAbonos = this.listaAbonos[this.sucursalSeleccionada];

            if (listaAbonos != null) {
                let sumaTransbank = 0;
                for (let index = 0; index < listaAbonos.length; index++) {
                    const element = listaAbonos[index];
                    if (element.medioPago == 'Transbank') {
                        sumaTransbank+= element.montoAbono;
                    }
                }

                total = sumaTransbank;
            }
        
            return total;
        },
        totalAbonosCheque: function() {
            let total = 0;
            let listaAbonos = this.listaAbonos[this.sucursalSeleccionada];

            if (listaAbonos != null) {
                let sumaCheque = 0;
                for (let index = 0; index < listaAbonos.length; index++) {
                    const element = listaAbonos[index];
                    if (element.medioPago == 'Cheque') {
                        sumaCheque+= element.montoAbono;
                    }
                }

                total = sumaCheque;
            }
        
            return total;
        },
        totalAbonosConvenio: function() {
            let total = 0;
            let listaAbonos = this.listaAbonos[this.sucursalSeleccionada];

            if (listaAbonos != null) {
                let sumaConvenio = 0;
                for (let index = 0; index < listaAbonos.length; index++) {
                    const element = listaAbonos[index];
                    if (element.medioPago == 'Convenio') {
                        sumaConvenio+= element.montoAbono;
                    }
                }

                total = sumaConvenio;
            }
        
            return total;
        },
        totalAbonosGeneral: function() {
            return this.totalAbonosEfectivo + this.totalAbonosTransbank + this.totalAbonosCheque + this.totalAbonosConvenio;
        },
        totalDiario: function() {
            let total = this.totalBazarGeneral + this.totalOTs;

            return total;
        },
        totalCajaGeneral: function() {
            return this.totalBazarGeneral + this.totalAbonosGeneral;
        },
        totalCajaEfectivo: function() {
            return this.totalBazarEfectivo + this.totalAbonosEfectivo;
        },
        totalCajaTransbank: function() {
            return this.totalBazarTransbank + this.totalAbonosTransbank;
        },
        totalCajaCheque: function() {
            return this.totalBazarCheque + this.totalAbonosCheque;
        },
        totalCajaConvenio: function() {
            return this.totalBazarConvenio + this.totalAbonosConvenio;
        },
    },
    components: {
        DatePicker
    }
}
</script>